<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Mobile Apps</h2>
      <p class="desc my-3">
        Texecom’s Premier Elite mobile apps have been updated and improved
        recently. The Premier Elite Mobile Apps makes you in charge of your
        household safety by presenting a renewed interface along with updated
        principal characteristics especially with regard to the message received
        from an application as well as access to the camera.
      </p>
      <ul class="desc my-3">
        <li>
          <p>
            The message you receive from applications keeps you informed of
            various system actions
          </p>
        </li>
        <li>
          <p>
            IP Camera control grants you access to your own household IP camera
            system
          </p>
        </li>
        <li>
          <p>Easier and more spontaneous to use</p>
        </li>
        <li>
          <p>Eliminates the necessity for stationary IP addresses</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/intrusion-detection/intrusion-systemimg-6.png"
        alt="Explain Storage?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "sixth-intrusion-section ",
};
</script>
