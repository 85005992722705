<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Perimeter Detectors</h2>
      <p class="desc my-3">
        A great variety of intellectual perimeter detection devices are offered
        by the Premier Series. With the help of both audible as well as
        vibration recognition options, sur plus safety measures are offered by
        Premier Series perimeter detectors by simply detecting wannabe burglars
        before any type of access is granted to the guarded premises.
      </p>
    </div>

    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/intrusion-detection/intrusion-systemimg-5.png"
        alt="Perimeter Detectors"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fiveth-intrusion-section ",
};
</script>
