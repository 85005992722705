<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Motion Detectors</h2>
      <p class="desc my-3">
        The Leading Compact Series of motion detectors are ideal for high-end
        residential, marketing, or low-level advertising applications. These
        detectors promote minute cautious styling and a choice of identifying
        technologies.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/intrusion-detection/intrusion-systemimg-4.png"
        alt="Explain Storage?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-intrusion-section ",
};
</script>
