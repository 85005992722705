<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">
        External Sounders with Graphic Inserts
      </h2>
      <p class="desc my-3">
        By offering a wide range of features along with incomparable performance
        for the purpose of benefiting end-users and installers, the latest
        Odyssey X Series is proved to bring a modern and innovative twist to
        external sounders.
      </p>
      <p class="desc my-3">
        The covers of Odyssey X Series are offered in black or white frames
        along with green, blue, white, and red lens choices. By choosing 6
        electronic backplates, you will be provided with illuminated Grade 2,3
        sounders, non-illuminated Grade 2,3 sounders, dummy as well as wireless
        sounder choices. With the help of Texecom website, you can customize
        graphic-inserts and order from your supplier right away.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/intrusion-detection/intrusion-systemimg-2.png"
        alt="Explain Storage?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-intrusion-section ",
};
</script>
