<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Internal wireless sounder</h2>
      <p class="desc my-3">
        Guard your premises entirely. Followed by the Ricochet Mesh Technology,
        is an internal wireless sounder, the Premier Elite 5Ci-W, which tends to
        deliver vigorous wireless connectivity along with outstanding sounder
        performance.
      </p>
      <p class="desc my-3">
        The Premier Elite 5Ci-W internal wireless sounder is perfect for
        apartments, retail, workplaces, and allows system designers to provide a
        better choice of positioning as compared to wired substitutes which
        result in greater safety solutions.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/intrusion-detection/intrusion-systemimg-1.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-intrusion-section ",
};
</script>
