<template>
  <intrusion-detection />
</template>

<script>
import IntrusionDetection from "../../components/solutions/intelligent-security/IntrusionDetection.vue";
export default {
  name: "intrusion-detection-page",
  components: { IntrusionDetection },
};
</script>
