<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Keypads</h2>
      <p class="desc my-3">
        A variety of highly efficient LED and LCD keypads presenting spontaneous
        operation, functional design, touchtone backside-illuminated keys, and a
        host of ground-breaking features. For a huge range of applications,
        Premier Series keypads provide remarkable functionality and can be
        utilized along with Premier Elite Series and Premier Series control
        panels.
      </p>
    </div>

    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/intrusion-detection/intrusion-systemimg-3.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "third-intrusion-section ",
};
</script>
