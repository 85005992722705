<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Control Panels</h2>
      <p class="desc my-3">
        Extremely customizable and interactive control panels for intermediate
        installations that demand a specialized safety solution. Premier control
        panels can be customized for a huge range of applications by presenting
        configuration settings as well as numerous programming opportunities.
      </p>
    </div>

    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/intrusion-detection/intrusion-systemimg-7.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "seventh-intrusion-section ",
};
</script>
